.input {
    margin: var(--space-sm);
    width: 100px;
    text-align: center;
}

.quantityContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

