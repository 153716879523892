.debug {
    position: relative;
    padding-top: var(--space-lg);
}

.closeBtn {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    text-align: right;
    padding: 10px;
}

.item {
    margin-bottom: var(--space-md);
}
