@media all and (min-height: 660px) {
    .mainWrap {
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.gameScreen {
    width: 375px;
    height: 660px;
    margin-left: auto;
    margin-right: auto;
    background-color: whitesmoke;
    position: relative;
    overflow-x: hidden;
}

.body {
    padding: var(--space-sm);
}

.info {
    margin-bottom: var(--space-unit);
}

.turnWipe {
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 1rem;
    position: absolute;
    transition: all 0.8s ease;
    z-index: 20;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    clip-path: polygon(0 0, 0% 0, -200px 100%, 0 100%);
    color: white;
    font-size: 2rem;
    background-color: var(--primary);
}

.wipe {
    clip-path: polygon(0 0, calc(100% + 200px) 0, 100% 100%, 0 100%);
}
