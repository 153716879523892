.modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.modalOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    cursor: pointer;
}

.modalContent {
    position: relative;
    width: 280px;
    padding: 2rem 1rem 2rem 1rem;
    background-color: white;
    border-radius: 10px;
    z-index: 20;
}

.closeButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    text-align: right;
    padding: 10px;
}
