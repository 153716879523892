.gameStatusWrap {
    margin-bottom: var(--space-sm);
}

.actions {
    text-align: center;
}

.teaTableWrap {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
}

.buttonStack {
    display: flex;
    justify-content: center;
    gap: var(--space-md);
    flex-direction: column;
}
