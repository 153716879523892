.galleon {
    width: 120px;
    overflow-x: hidden;
}

.imgWrap {
    width: 120px;
    height: 134px;
    position: relative;
    overflow: hidden;
    background-image: url("./galleon.svg");
    background-position: center center;
    background-repeat: no-repeat;
}

.imgWrapLeft {
    background-image: url("./galleon-left.svg");
}

.name {
    font-size: 0.8rem;
    line-height: 0.9rem;
    height: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
}

.face {
    position: absolute;
    font-size: 2rem;
    line-height: 2rem;
    top: 80px;
    left: 10px;
}

.faceLeft {
    left: auto;
    right: 10px;
}
