.seaBattle {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.rhs {
    display: flex;
    justify-content: right;
}

.messages {
    text-align: center;
    height: 200px;
    overflow-y: hidden;
    position: relative;
}

.message {
    margin-bottom: 0.2rem;
}

.topMessage {
    opacity: 1;
    font-weight: bold;
}

.bottomGradient {
    background-image: linear-gradient(
        to bottom,
        rgba(245, 245, 245, 0),
        rgba(245, 245, 245, 1)
    );
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 60px;
}
