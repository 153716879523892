.start {
    text-align: center;
    margin-top: var(--space-lg);
}

.galleon {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.pickName {
    display: flex;
    justify-content: center;
    margin-bottom: var(--space-lg);
}

.pickName input {
    text-align: center;
}

.reset {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin-left: var(--space-xs);
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;
}

.logo {
    font-size: 38px;
}

.intro {
    margin-bottom: var(--space-lg);
}

.version {
    margin-top: var(--space-lg);
    font-size: 12px;
}
