.change-location {
    padding-top: 1rem;

    & .town-list {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;

        & label {
            display: inline-block;
            margin-bottom: 1rem;
            cursor: pointer;
            text-align: left;
            width: 150px;
        }

        & .disabled {
            opacity: 0.5;
            cursor: default;
        }

        & input {
            margin-right: 1rem;
        }
    }

    & .buttons {
        text-align: center;
    }
}

.something-happening {
    display: inline-block;
    margin-left: 0.4rem;
}

.town-feature {
    margin-left: 0.4rem;
}