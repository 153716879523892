.header {
    height: 30px;
    border-bottom: 1px solid var(--secondary-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: var(--space-xs);
}

.turns {
    width: 80px;
    font-size: 0.8rem;
    text-align: right;
}

.menuTrigger {
    width: 80px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: var(--space-sm);
}

.town {
    font-size: 0.8rem;
}
